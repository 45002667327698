/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/style.scss";

@media only screen and (min-width: 420px) {
	#main-content {
		max-width: 420px;
		left: calc(50% - 210px) !important;
	}
}
ion-back-button {
	--icon-margin-start: 0px;
	--icon-padding-start: 0px;
	--margin-start: 0px;
	--padding-start: 0px;
	--min-width: auto;
	&::part(native) {
		--padding-start: 0px;
		padding: 0px !important;
		margin: 0px !important;
	}
}
// CUSTOM PROGRESS BAR
.custom-progress-bar {
	width: 100%;
	margin-left: 0%;
	margin-right: 0%;
	height: 3px;
	--buffer-background: rgba(var(--ion-color-light-rgb), 0.8) !important;
	--progress-background: var(--ion-color-primary-contrast);
}

// CUSTOM TITLES
h2 {
	color: var(--ion-color-tertiary);
	font-size: 1.543rem;
	line-height: 2rem;
	font-weight: bold;
	&.light {
		color: var(--ion-color-light);
	}
	&.big {
		font-size: 2.18rem;
	}
}
h3 {
	color: var(--ion-color-tertiary);
	font-size: 1.43rem;
	line-height: 2rem;
	font-weight: bold;
}
p {
	color: var(--ion-color-light);
}
hr {
	&.dotted {
		background-image: linear-gradient(to right, rgba(187, 187, 187, 0.7) 20%, rgba(187, 187, 187, 0) 10%);
		background-position: top;
		background-size: 10px 3px;
		background-repeat: repeat-x;
		margin: 2rem 1.5rem;
	}
	&.half {
		margin: 2.5rem 25%;
	}
}

.plaey-modal {
	max-width: 420px;
	left: calc(50% - 210px) !important;
	--background: rgba(0, 0, 0, 0.3) !important;
	.modal-wrapper {
		height: unset;
	}
}
.plaey-modal-primary {
	max-width: 420px;
	left: calc(50% - 210px) !important;
	--background-color: var(--ion-color-dark) !important;
	&::part(content) {
		width: 420px;
		height: 100%;
	}
}

// CUSTOM INPUTS STYLE
ion-input,
ion-select {
	--padding-bottom: 1rem !important;
}
// UTILITIES
.d-block {
	width: 100%;
	display: block;
}
.centered {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.centered-x {
	width: 100%;
	display: flex;
	justify-content: center;
}
.centered-y {
	height: 100%;
	display: flex;
	align-items: center;
}
.rotate-180 {
	transform: rotate(180deg);
}
.rotate-90 {
	transform: rotate(90deg);
}
.rotate-270 {
	transform: rotate(270deg);
}
.bg-cover {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-size: cover;
	background-repeat: no-repeat;
}
.overlay-cover {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: black;
	opacity: 0.6;
}
.clickable {
	cursor: pointer;
}
.bold {
	font-weight: bold !important;
}
.actions-toolbar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 2rem 0rem;
}
.header-toolbar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0rem 1rem;
}
// END UTILITIES
ion-input {
	--color: var(--ion-color-light) !important;
}
ion-label {
	--color: rgba(--ion-color-light-rgb, 0.8) !important;
	font-size: 15px;
}
ion-button {
	--border-radius: 5px;
	--border-width: 0.5px;
	font-size: 1.12rem;
	min-height: 50px;
	min-width: 180px;
	ion-label {
		padding: 0.3rem 0.5rem;
		font-size: 1.12rem !important;
		line-height: 0.5rem;
		&.primary {
			--color: var(--ion-color-primary-contrast);
			--border-color: var(--ion-color-light) !important;
		}
	}
	&.primary {
		--color: var(--ion-color-primary-contrast);
		--border-color: var(--ion-color-light) !important;
	}
	&.btn-submit-gray {
		--background: #555750;
		--border-radius: 50px;
		width: auto;
		min-height: 35px;
		min-width: 150px;
		font-size: 0.75rem;
	}
	&.contrast {
		--color: var(--ion-color-bg);
		min-width: 120px;
		font-size: 13px;
		font-weight: bold;
		--border-color: var(--ion-color-primary-contrast) !important;
		--background: var(--ion-color-primary-contrast) !important;
		&.ion-activated {
			--color: var(--ion-color-primary-contrast);
		}
	}
}
.btn-transparent {
	cursor: pointer;
	font-size: 0.75rem;
}

ion-range {
	--bar-height: 20px !important;
	--knob-background: #044553;
	--knob-size: 25px !important;
	--bar-background: #47676e99;
	--bar-background-active: linear-gradient(
		90deg,
		var(--ion-color-primary) -20%,
		var(--ion-color-primary-contrast) 140%
	);

	--bar-border-radius: 50px;
	--knob-box-shadow: 0px 0px 0px 2px #fff;
	@media (min-width: 600px) {
		--knob-size: 42px !important; // => 28px DESKTOP
	}
}
ion-datetime {
	--background: var(--ion-color-primary);
	--ion-color-step-150: var(--ion-color-dark);
}
ion-modal.date {
	--width: 290px;
	--height: 382px;
	--border-radius: 0px;
	--ion-background-color: transparent;
	ion-datetime {
		height: 382px;
		--ion-color-base: var(--ion-color-primary-contrast) !important;
		--ion-color-contrast: var(--ion-color-dark) !important;
		--background-rgb: var(--ion-color-primary);
	}
}
ion-popover {
	--box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.fab-button {
	--border-radius: 25px;
	--background: var(--ion-color-dark);
	--color: var(--ion-color-primary-contrast);
	height: 50px;
	width: 50px;
	min-width: 50px;
	position: relative;
	border-radius: 25px;
	border: 0.5px var(--ion-color-dark) solid;
	ion-icon,
	img {
		height: 1.3rem;
		width: 1.3rem;
	}
}
.fab-no-bg {
	--background: transparent !important;
	--box-shadow: none !important;
	ion-icon {
		height: 1rem;
		width: 1rem;
	}
}
ion-item.button-icon {
	--padding-top: 0.4rem;
	--padding-bottom: 0.4rem;
	* {
		font-size: 0.875rem;
	}
}
ion-alert {
	--background: var(--ion-color-dark) !important;
	.alert-wrapper {
		box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
			rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important;
	}
	button {
		color: var(--ion-color-primary-contrast) !important;
	}
}
ion-loading {
	--spinner-color: var(--ion-color-primary-contrast) !important;
	--ion-color-step-850: var(--ion-color-ligth) !important;
	--background: var(--ion-color-dark) !important;
}
ion-tab-bar {
	--border: none !important;
}
ion-textarea {
	min-height: 200px;
	textarea {
		min-height: 200px;
	}
}
ion-header.w-pm {
	margin-top: 2.5rem;
	max-width: calc(100% - 28px);
}
ion-note.helper {
	font-size: 13px;
	color: #959a8f;
	margin-top: -10px;
}

ion-footer {
	&.form-footer {
		position: absolute;
		bottom: 150px;
	}
}

ion-list {
	.selectable-item {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--ion-color-dark);
		color: var(--ion-color-light);
		border-radius: 5px;
		height: 58px;
		margin: 1rem;
		&.active {
			box-shadow: rgba(var(--ion-color-primary-contrast-rgb), 0.56) 0px 0px 20px -2px;
		}
		span {
			margin-left: 0.8rem;
			height: 1.68rem;
			width: 1.68rem;
		}
	}
}

.full-plaey-modal {
	--width: 100%;
	max-width: 420px;
	left: calc(50% - 210px) !important;
	--height: 100%;
	:host ion-content {
		max-width: 450px !important;
	}
}
.end-x {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.subtitle-desc {
	color: #d2d1c5;
	font-size: 0.93rem;
	line-height: 1.43rem;
	padding: 0px 1rem;
	margin-bottom: 0px;
	margin-top: 0px;
}
.user-profile-icon {
	height: 35px;
	width: 35px;
	border-radius: 17.5px;
	border: 2px solid var(--ion-color-primary-contrast);
	background-color: var(--ion-color-dark);
	display: flex;
	align-items: center;
	margin: 0px;
	padding: 0px;
	font-weight: bold;
	color: white;
}
label.user-profile-icon {
	width: 35px;
	display: flex;
	justify-content: center;
}

.icon-title {
	display: inline-flex;
	align-items: center;
	height: 60px;
	.icon {
		margin-right: 1rem;
		height: 100%;
		display: flex;
		align-items: center;
		* {
			height: 35px;
		}
	}
}

.page-content {
	height: calc(100% - 88px);
}

.sessions-popover {
	--width: 320px;
}
